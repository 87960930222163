import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAppBarColor: false,
  },
  getters: {
    isAppBarColor(state) {
      return state.isAppBarColor
    },
  },
  mutations: {
    setAppBarColor(state, value) {
      state.isAppBarColor = value
    },
  },
  actions: {
    updateAppBarColor({ commit }, value) {
      commit('setAppBarColor', value)
    },
  },
  modules: {
  }
})
