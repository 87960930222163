<template>
  <v-sheet>
    <v-app-bar
      color="white"
      class="mobile-app-bar"
      elevation="0"
    >
      <v-app-bar-nav-icon 
       @click.stop="drawer = !drawer"
        color="black"
      >
      </v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex justify-end ml-auto pr-4">
        <v-img 
        src="@/assets/logo/logo_full.png" 
        height="10" 
        width="96.3347"
        @click="navigateTo('/')"
        class="cursor"
      ></v-img>
      
      </v-toolbar-title>
      <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
          class="m-drawer"
          @click="overlay = !overlay"
        >
          <v-col
            cols="12"
            class="pa-0 d-flex flex-column align-content-space-between"
          >
            <div
              class="text-start font-weight-bold text-h7  d-flex align-center pl-8 mobile-top-menu-title"
            >
              <v-img
                src="@/assets/logo/logo_full.png"
                max-width="130"
                max-height="13.1933"
                class="mr-2"
                @click="() => $router.push('/')"
              ></v-img>
              <v-btn
                icon
                @click="drawer = false"
                class="ml-auto pr-6 black--text"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              </div>
            <div
              cols="12"
              class="pa-0 d-flex flex-column text-left mobile-menu-item-1"
            >
            <!-- <v-btn
              elevation="0"
              color="white"
              @click="dialog = false"
              class="justify-start align-center pa-0 ml-8 mt-6"
            >
              <v-sheet
                elevation="0"
                class="text-start font-weight-bold text-h7  d-flex align-center"
                @click="() => $router.push('/about')"
              >
                <v-img
                  src="@/assets/logo/arckitchen.png"
                  max-width="16"
                  max-height="10.5369"
                  class="mr-2"
                ></v-img>
                <div>아크키친?</div>
              </v-sheet>
            </v-btn> -->
              <v-btn
                elevation="0"
                color="white"
                @click="dialog = false"
                class="justify-start align-center pa-0 ml-8 mt-4"
              >
                <v-sheet
                  elevation="0"
                  class="text-start font-weight-bold text-h6  d-flex align-center"
                  @click="() => $router.push('/store-infomation')"
                >
                  <v-img
                    src="@/assets/images/shop_info.png"
                    max-width="20"
                    max-height="16.4706"
                    class="mr-2"
                  ></v-img>
                  <div>매장정보</div>
                </v-sheet>
              </v-btn>
            <!-- <v-btn
              elevation="0"
              color="white"
              @click="dialog = false"
              class="justify-start align-center pa-0 ml-8 mt-4"
            >
              <v-sheet
                elevation="0"
                class="text-start font-weight-bold text-h6  d-flex align-center"
                @click="() => $router.push('/case')"
              >
                <v-img
                  src="@/assets/images/case.png"
                  max-width="20"
                  max-height="17.5257"
                  class="mr-2"
                ></v-img>
                <div>운영사례</div>
              </v-sheet>
            </v-btn>  -->
              <v-btn
                elevation="0"
                color="white"
                @click="dialog = false"
                class="justify-start align-center pa-0 ml-8 mt-4"
              >
                <v-sheet
                  elevation="0"
                  class="text-start font-weight-bold text-h6  d-flex align-center"
                  @click="() => $router.push('/entering')"
                >
                  <v-img
                    src="@/assets/images/enter_shop.png"
                    max-width="20"
                    max-height="17.0588"
                    class="mr-2"
                  ></v-img>
                  <div>입점신청</div>
                </v-sheet>
              </v-btn>
              <v-btn
                elevation="0"
                color="white"
                @click="dialog = false"
                class="justify-start align-center pa-0 ml-8 mt-4"
              >
                <v-sheet
                  elevation="0"
                  class="text-start font-weight-bold text-h6  d-flex align-center"
                  @click="navigateTo('/franchise')"
                >
                  <v-img
                    src="@/assets/images/franch_shop.png"
                    max-width="20"
                    max-height="19.8665"
                    class="mr-2"
                  ></v-img>
                  <div>프랜차이즈 문의</div>
                </v-sheet>
              </v-btn> 
              <v-btn
                elevation="0"
                color="white"
                @click="dialog = false"
                class="justify-start align-center pa-0 ml-8 mt-4"
              >
                <v-sheet
                  elevation="0"
                  class="text-start font-weight-bold text-h6 d-flex align-center"
                  @click="navigateTo('https://pf.kakao.com/_aHzQK')"
                >
                  <v-img
                    src="@/assets/images/customer.png"
                    max-width="20"
                    max-height="24.4138"
                    class="mr-2"
                  ></v-img>
                  <div>고객센터</div>
                </v-sheet>
              </v-btn>
            </div> 
            <div 
              class="pa-0 ma-0 d-flex align-center mobile-menu-item-2"
            >
              <v-img 
                src="@/assets/images/popup/popup_img.png"
                class="m-popup"
                contain
                @click="navigateTo('https://pf.kakao.com/_aHzQK')"
              ></v-img>
            </div> 
          </v-col>
        </v-navigation-drawer>  
    </v-app-bar>
    
  </v-sheet>
  
</template>

<script>
export default {
  
  computed: {
  },
  data: () => ({
      drawer: false,
      group: null,
      
    }),
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    },
  },
  navigateTo(menu) {  
      if(menu.title == '고객센터'){
        window.open(menu.link)
      } 
      else if(menu == '/') {
        this.$router.push(menu)
      } 
      else this.$router.push(menu.link)
    },
  }

</script>

<style lang="scss" scoped>
.appbar {
  position: sticky;
  top: 0px;
  z-index: 99;
}
.mobile-app-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: white;

}
.mobile-appbar > .theme--light.v-toolbar.v-sheet {
  background-color: transparent !important;
}
.mobile-menu-wrap {
  height: 100% !important;
}
.mobile-top-menu-title{
  height: 56px;
  border-bottom: 1px solid rgb(240, 240, 240);
}
.mobile-menu {
  height: 100vh;
}
.mobile-menu-item-1 {
  width: 100%;
  height: 46vh !important;
}
.mobile-menu-item-2 {
  width: 100%;
  height: 48vh !important;
  background-color: #feccbe;
}
.list {
  background-color: transparent !important;
}
.btn-bar {
  background-color: transparent !important;
}
.round {
  border-radius: 0 !important;
}
.logo-margin {
  margin-top: 80% !important;
}
.m-drawer {
  height: 100vh !important;
  width: 100% !important;
  z-index: 99;
  
}
.m-popup {
  width: 100%;
  height: 100%;
}
</style>
