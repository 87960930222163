import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/plugins/router'
import store from '@/plugins/vuex'
import VueKinesis from 'vue-kinesis'
import VueTyperPlugin from 'vue-typer'
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import * as VueWindow from '@hscmap/vue-window'



Vue.config.productionTip = false
Vue.use(VueKinesis)
Vue.use(VueTyperPlugin)
Vue.use(VueWindow)
const firebaseConfig = {
  apiKey: "AIzaSyDBKMcXRlT_7LKuC5TUGjSUNc3nRSKpjG8",
  authDomain: "arc-kitchen-web.firebaseapp.com",
  projectId: "arc-kitchen-web",
  storageBucket: "arc-kitchen-web.appspot.com",
  messagingSenderId: "141385341148",
  appId: "1:141385341148:web:bce2186b104cb018f07c19",
  measurementId: "G-L54JXTB7NT"
};
firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
