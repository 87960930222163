<template>
  <v-app>
    <header id="header">
      <Header v-if="$vuetify.breakpoint.mdAndUp"/>
      <MobileHeader v-if="$vuetify.breakpoint.smAndDown"/>
    </header>
    <main class="main">
      <router-view />
    </main>
    <!-- <footer class="footer">
      <Footer/>
    </footer> -->
    <!-- <div>
      <PopUp class="position-popup"/>
    </div> -->
    
    
    <hsc-window-style-metal  v-if="$vuetify.breakpoint.smAndDown">
      <hsc-window :top="140" :left="30" :isOpen.sync="isOpen">
        <div 
          class="popup_cont"
        >
          <v-img
            src="@/assets/images/popup/popup_img.png"
            :max-height="300"
            :max-width="300"
            @click="navigateTo('https://pf.kakao.com/_aHzQK')"
          >
          </v-img>  
        </div>
        <div class="popup_btn">
          <button 
            class="ml-10 font-weight-bold primary-blue--text"
            @click="navigateTo('https://pf.kakao.com/_aHzQK')"
          >자세히보기</button>
          <button 
            class="mr-10 font-weight-bold"
            @click="isOpen =! isOpen"
          >닫기</button>
        </div>
      </hsc-window>
    </hsc-window-style-metal>
    <hsc-window-style-metal v-if="$vuetify.breakpoint.mdAndUp">
      <hsc-window :top="90" :left="600" :isOpen.sync="isOpen">
        <div class="popup_cont">
          <v-img
            src="@/assets/images/popup/popup_img.png"
            :max-height="600"
            :max-width="450"
            @click="navigateTo('https://pf.kakao.com/_aHzQK')"
          >
          </v-img>
        </div>
        <div class="popup_btn">
          <button 
            class="ml-16 font-weight-bold primary-blue--text"
            @click="navigateTo('https://pf.kakao.com/_aHzQK')"
          >자세히보기</button>
          <button 
            class="mr-16 font-weight-bold"
            @click="isOpen =! isOpen"
          >닫기</button>
        </div>
      </hsc-window>
    </hsc-window-style-metal>
    

    <v-img
      class="kakao"
      :class="{'m_kakao' : $vuetify.breakpoint.smAndDown}"
      src="@/assets/images/kakao_btn.png"
      :max-height="$vuetify.breakpoint.mdAndUp ? 200 : 90" 
      :max-width="$vuetify.breakpoint.mdAndUp ? 200 : 90"
      @click="navigateTo('https://pf.kakao.com/_aHzQK')"
    >
    </v-img>
    <v-img
      class="kakao"
      :class="{'m_kakao' : $vuetify.breakpoint.smAndDown}"
      src="@/assets/images/kakao_btn.png"
      :max-height="$vuetify.breakpoint.mdAndUp ? 200 : 90" 
      :max-width="$vuetify.breakpoint.mdAndUp ? 200 : 90"
      @click="navigateTo('https://pf.kakao.com/_aHzQK')"
    >
    </v-img>
  
  </v-app>
</template>

<script>
// import Footer from '@/views/home/footer/Footer'
import Header from '@/views/home/header/Header.vue'
import MobileHeader from '@/views/home/header/MobileHeader.vue'
// import PopUp from '@/views/home/popuplayer/PopUp.vue'


export default {
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    },
    scrollToTop() {
      document
        .getElementById('header')
        .scrollIntoView({ behavior: 'smooth'})
    }
  },
  components: {
    // Footer,
    Header,
    MobileHeader,
    // PopUp,
  },
  mounted() {
    if(window.innerWidth < 961){
      this.x = window.innerWidth * 0.1
      this.y = window.innerHeight * 0.1
    } else {
      this.x = window.innerWidth * 0.35
      this.y = window.innerHeight * 0.2
    }
  },
  computed: {
    // setCurrentRoute(to){
    //   this.currentRoute.name = to.name 
    //   this.currentRoute.path = to.path 
    // }
  },
  data() {
    return {
      currentRoute : {
        path: '',
        name: '',
        alert: true,
        dialog: false,
      },
      isOpen: true,
      x: null,
      y: null,
    }  
  },
  
  watch: {
    $route() {
      // const { path , name } = this.$route
      // setCurrentRoute(to.name)
    },
  },
}

</script>

<style lang="scss">
@import "https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,200,400,500,700,900&display=swap&subset=korean";
* {
  font-family: "Noto Sans KR", sans-serif;
}
.popupsheet {
  position: fixed;
  z-index: 999;
}
.popup_btn {
  display: flex;
  justify-content: space-between;
  width:100%;
  word-break: break-word;
  padding: 10px !important;
}
.popup_box .popup_btn a:after {
  content:'';
  display:block;
  position:absolute;
  top:26px;
  right:29px;
  width:1px;
  height:21px;
  background:#fff;
  -moz-transform: rotate(45deg); 
  -webkit-transform: rotate(45deg); 
  -ms-transform: rotate(45deg); 
  -o-transform: rotate(45deg); 
  transform: rotate(45deg);
}
.popup_box .popup_btn a:before {
  content:'';
  display:block;
  position:absolute;
  top:26px;
  right:29px;
  width:1px;
  height:21px;
  background:#fff;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); 
  -ms-transform: rotate(-45deg); 
  -o-transform: rotate(-45deg); 
  transform: rotate(-45deg);
}
.popup_box .popup_btn a {
  position: relative; 
  display: table-cell; 
  height:70px; 
  color:#fff; 
  font-size:17px;
  text-align:center;
  vertical-align:middle;
  text-decoration:none; 
  background:#102c5c;
}
.popup_box .popup_btn a.close_day {
  background:#5d5d5d;
}
.popup_box .popup_btn a.close_day:before, .popup_box .popup_btn a.close_day:after{
  display:none;
}
.window {
  background-color: #fff !important;
}
.titlebar[data-v-300294cc]{
  padding: 0.1 !important;
  background: none !important;
  background-color: #fff !important;
}
.content {
  padding: 0 !important;
  background-color: #fff !important;
}
.v-responsive__content {
  cursor: pointer !important;
}
.kakao {
  position: fixed;
  bottom: 70px;
  right: 120px;
  z-index: 999;
}
.m_kakao {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 90;
}
.cursor {
  cursor: pointer !important;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: 10px; 
  scroll-behavior: smooth;
}

.main {
  padding: 0 !important;
  z-index: 0;
}

.footer {
  padding: 0 !important;
  
  bottom:0;
}
.header {
  z-index: 99;
}
.position-popup {
  position: relative;
}
</style>
