<template>
  <v-app-bar app flat :prominent="$vuetify.breakpoint.mdAndUp" class="app-bar pa-0">
    <v-card 
      width="1000" 
      height="80" 
      color="transparent"
      max-width="1000" 
      elevation="0" 
      flat
      class="mx-auto d-flex align-center justify-space-between pa-0"
      :class="{'menu': $vuetify.breakpoint.mdAndUp}"
    >
      <v-img 
        src="@/assets/logo/logo_full.png" 
        :max-height="$vuetify.breakpoint.mdAndUp ? 36 : 18" 
        :max-width="$vuetify.breakpoint.mdAndUp ? 260 : 80"
        @click="navigateTo('/')"
        class="cursor"
      />
      <v-row class="pa-0 text-body-1 justify-end ma-0 font-weight-bold">
        <!-- <div
          v-for="(menu, index) in menus"
          @click="navigateTo(menu)"
          :key="index"
          class="pl-4 font-weight-bold cursor"
        >{{ menu.title }}
        </div> -->
        <div 
          class="mr-5"
          @click="navigateTo('/store-infomation')"  
        >매장정보</div>
        <div 
          class="mr-5"
          @click="navigateTo('/entering')"    
        >입점신청</div>
        <div 
          class="mr-5"
          @click="navigateTo('/franchise')"
        >프랜차이즈문의</div>
        <div
          @click="navigateTo('https://pf.kakao.com/_aHzQK')"
        >고객센터</div>
      </v-row>
    </v-card>
  </v-app-bar>
</template>


<script>
export default {
  // computed: {
  // },
  // data() {
  //   return {
  //     menus: [
  //       { title: '매장정보', link: 'store-infomation' },
  //       { title: '입점신청', link: 'entering' },
  //       { title: '프랜차이즈문의', link: 'https://pf.kakao.com/_aHzQK' },
  //       { title: '고객센터', link: 'https://pf.kakao.com/_aHzQK' },
  //     ]
  //   }
  // },
  // methods: {
  //   navigateTo(menu) {  
  //     if(menu.title == '고객센터'){
  //       window.open(menu.link)
  //     } 
  //     else if(menu == '/') {
  //       this.$router.push(menu)
  //     } 
  //     else this.$router.push(menu.link)
  //   },
  // }
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .menu {
//   margin-top: 28px;
// }
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: rgba(255, 255, 255, 1);
}
.v-toolbar__content {
  padding: 0 !important;
  height: 100px !important;
}
.v-app-bar {
  height: 80px !important;
  z-index: 99; 
}
</style>