import importView from '../utils/importView'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: importView('home/main', 'Main'),
    children : [
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: importView('home/main/about', 'About'),
  },
  {
    path: '/store-infomation',
    name: 'storeInfomation',
    component: importView('home/main/store-infomation', 'StoreInfomation'),
  },
  {
    path: '/entering',
    name: 'entering',
    component: importView('home/main/entering', 'Entering'),
  },
  {
    path: '/franchise',
    name: 'franchise',
    component: importView('home/main/franchise', 'Franchise'),
  },
  {
    path: '/case',
    name: 'case',
    component: importView('home/main/case', 'Case'),
  },
  {
    path: '/signin',
    name: 'signin',
    component: importView('home/admin/auth', 'SignIn'),
  },
  // {
  //   path: '/adminsignup87257777',
  //   name: 'signup',
  //   component: importView('home/admin/auth', 'SignUp'),
  // },
]

export default routes