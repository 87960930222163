import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ko from 'vuetify/lib/locale/ko'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ko },
    current: 'ko',
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#0343F9',
        'primary-red' : '#F90050',
        'primary-blue' : '#0443F9',
        'lightly-dark' : '#333333',
        'premium-gold' : '#FEDA00',
        'bg-blue' : '#1410DE',
        'lightly-dark-2' : '#444444',
        'lightly-gray' : '#D4D4D4',
        'lightly-sky-blue' : '#FDFFFF'
      },
    },
  },
})
